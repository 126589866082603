/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue()"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        {{
          type === 0
            ? "Cantidad de ventas pendientes de enviar"
            : "Cantidad de ventas enviadas completamente"
        }}: <b>{{ filteredSales.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="filteredSales"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="sales"
        :checked-rows.sync="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
        scrollable
      >
        <template #empty>
          <div class="has-text-centered">
            {{
              type === 0
                ? "No hay ventas pendientes de enviar"
                : "No hay ventas completamente enviadas"
            }}
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showMaterialReceptions(props.row)"
                    >Ver envios de material</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="sendSaleArticles(props.row)"
                    v-if="
                      props.row.STATUS_ENV !== 'C' &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.sendSaleMaterial ===
                        'S' &&
                      permissions &&
                      permissions.sales.sendSaleMaterial === 'S'
                    "
                    >Nuevo envio de material</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="sendSaleArticles(props.row, true)"
                    v-if="
                      props.row.STATUS_ENV !== 'C' &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales.sendSaleMaterial ===
                        'S' &&
                      permissions &&
                      permissions.sales.sendSaleMaterial === 'S'
                    "
                    >Enviar todo el material faltante</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="validateSaleMaterialSubstraction(props.row, true)"
                    >Validar material enviado</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="
                      startConfirmEvent(
                        'success',
                        function () {
                          finalMaterialReception(props.row, 'C');
                        },
                        'cambiar estatus a completa'
                      )
                    "
                    v-if="
                      type === 0 &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales
                        .confirmAllMaterialDelivery === 'S' &&
                      permissions &&
                      permissions.sales.confirmAllMaterialDelivery === 'S'
                    "
                    >Cambiar estatus a completa</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="
                      startConfirmEvent(
                        'success',
                        function () {
                          finalMaterialReception(props.row, 'I');
                        },
                        'cambiar estatus a incompleta'
                      )
                    "
                    v-if="
                      type === 1 &&
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales
                        .confirmAllMaterialDelivery === 'S' &&
                      permissions &&
                      permissions.sales.confirmAllMaterialDelivery === 'S'
                    "
                    >Cambiar estatus a incompleta</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="editMaterialDelivery(props.row)"
                    v-if="
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.sales
                        .editMaterialDeliverySale === 'S' &&
                      permissions &&
                      permissions.sales.editMaterialDeliverySale === 'S'
                    "
                    >Editar envio de material</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import PorteCardsDetails from "./PorteCardsDetails.vue";
import AddPorteCardModal from "./AddPorteCardModal";
import SendSaleArticles from "./SendSaleArticles";
import SaleDeliveriesDetailsList from "./SaleDeliveriesDetailsList";
import EditMaterialDeliverySale from "./EditMaterialDeliverySale";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import {
  openConfirmationModalSucces,
  openConfirmationModalDanger,
} from "@/utils/component-fns";
import { basicRuleOfThree, toFixedNoRound } from "@/utils/fns";
import { EventBus } from "@/event-bus";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "SaleDeliveriesList",
  props: ["type"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedSales: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_VENTA",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusFormatted",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusFacFormatted",
          label: "Estatus de timbrado",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusDeliveryFormatted",
          label: "Estatus de envio",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateSending,
        },
        {
          field: "NOMBRE_VENDEDOR",
          label: "Vendedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedSendingDate",
          label: " Fecha de envio",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateSending,
        },
        {
          field: "vehiculeFormatted",
          label: " Vehiculo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "clientReceptionFormatted",
          label: " El cliente recoge el material",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "posFormatted",
          label: "Punto de venta",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_CLIENTE",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de la venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "RESTA_FOR",
          label: "Porcentaje enviado",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
      ],
      moment: moment,
      filterWarehouses: [],
      masks: Masks,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    await this.reloadInformation();
  },
  mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }

    EventBus.$on("refetchMaterialDeliveries", async () => {
      await this.changeFilterValue();
    });
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETSALES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );

      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      if (this.$store.getters.COMMERCIALBUSINESS.length === 0) {
        allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
      }
      if (this.$store.getters.SATCFDIUSES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
      }
      if (this.$store.getters.PAYMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      }
      if (this.$store.getters.SATREGIMENES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
      }
      if (this.$store.getters.PACKAGINGS.length === 0) {
        allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
      }
      if (this.$store.getters.TRANSPORTATIONPERMISSIONS.length === 0) {
        allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
      }
      allPromises.push(this.$store.dispatch("GETVEHICULES"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      if (this.$store.getters.TRANSPORTATIONCONFIGURATION.length === 0) {
        allPromises.push(
          this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION")
        );
      }
      if (this.$store.getters.TRAILERTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
      }
      if (this.$store.getters.WEIGHTUNITS.length === 0) {
        allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
      }
      if (this.$store.getters.DANGEROUSMATERIALS.length === 0) {
        allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
      }

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddPorteCard(sale) {
      let newSale = this.formatSale(sale);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPorteCardModal,
        props: {
          saleInformation: newSale,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showPorteCardsDetails(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PorteCardsDetails,
        props: {
          saleInformation,
          porteCardsObjects: saleInformation.CARTAS_PORTE,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    formatSale(sale) {
      let currentItems = [];
      for (let singleArticle of sale.VENTA_DET) {
        const formattedSingleArticle = {
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          presentation: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
          priceList: singleArticle.LISTA_PRECIOS,
          quantity: singleArticle.CANTIDAD_PE,
          quantitySended: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          quantitySendedOriginal: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          RENGLON: singleArticle.RENGLON,
          price: singleArticle.COSTO_UNI,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          total: singleArticle.TOTAL,
          costTotal: singleArticle.COST_GTO,
          discountTotal: singleArticle.DESC_GTO,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO ? singleArticle.EXENTO : "N",
          COSTO_MIN_VTA: singleArticle.COSTO_MIN_VTA,
          UTILIDAD_UNI: singleArticle.utilityTotal,
          UTILIDAD: singleArticle.utilityTotal,
        };

        if (
          singleArticle.PRESENTACION === "UB" &&
          singleArticle.CLAVE_ART.UNIDAD_EMP
        ) {
          formattedSingleArticle.presentationName =
            singleArticle.CLAVE_ART.UNIDAD_EMP.DESCRIP_MED;
        } else if (
          singleArticle.PRESENTACION === "UB" &&
          !singleArticle.CLAVE_ART.UNIDAD_EMP
        ) {
          formattedSingleArticle.presentationName = `${singleArticle.CLAVE_ART.DESCRIPCION} Unidad de medida base`;
        } else {
          // Set presentation name
          for (const singlePresentation of singleArticle.CLAVE_ART
            .PRESENTACIONES) {
            if (
              singlePresentation._id.toString() ===
              singleArticle.PRESENTACION.toString()
            ) {
              formattedSingleArticle.presentationName =
                singlePresentation.NOMBRE;
            }
          }
        }

        currentItems.push(formattedSingleArticle);
      }
      let saleInformation = {
        _id: sale._id,
        CLAVE_VENTA: sale.CLAVE_VENTA,
        CLIENTE: sale.CLAVE_CLIENTE._id,
        ALMACEN: sale.CLAVE_ALMACEN._id,
        COSTOS: sale.COSTOS ? sale.COSTOS : [],
        DESCS: sale.DESCS ? sale.DESCS : [],
        OBSERVA: sale.OBSERVA,
        OBSERVA_IMP: sale.OBSERVA_IMP,
        currentItems: currentItems,
        FECHA: sale.FECHA,
        subtotal: sale.SUBTOTAL,
        total: sale.TOTAL,
        discounts: sale.DESC_GTO,
        costs: sale.COST_GTO,
        iva: sale.IVA,
        ieps: sale.IEPS,
        exempt: sale.EXENTO,
        shippingQuantity: sale.FLETE,
        shippingPercentage: sale.PORC_FLETE,
        printTimes: sale.IMPRESO,
        STATUS: sale.STATUS,
        EXENTO: sale.EXENTO,
        CLAVE_USO_SAT: sale.CLAVE_USO_SAT,
        CLAVE_REGIMEN: sale.CLAVE_REGIMEN,
        METODO_P: sale.METODO_P,
        CONDICION_P: sale.CONDICION_P,
        PERI_PAGO: sale.PERI_PAGO,
        FORMA_P: sale.FORMA_P,
        REFERENCIA: sale.REFERENCIA,
        NO_CUENTA: sale.NO_CUENTA,
        NO_CLABE: sale.NO_CLABE,
        NO_TARJETA: sale.NO_TARJETA,
        NO_CHEQUE: sale.NO_CHEQUE,
        STATUS_FAC: sale.STATUS_FAC,
        requireInvoice: sale.requireInvoice,
        requireInvoiceAfterSend: sale.requireInvoiceAfterSend,
        requireParcialInvoiceAfterSend: sale.requireParcialInvoiceAfterSend,
        relateCFDI: sale.relateCFDI,
        divideCFDI: sale.divideCFDI,
        UUID_CFDI: sale.UUID_CFDI,
        TIPO_R: sale.TIPO_R,
        VENDEDOR: sale.VENDEDOR,
        UTILIDAD: sale.UTILIDAD,
        DESBLOQUEO_CLIENTE: sale.DESBLOQUEO_CLIENTE,
        DESBLOQUEO_ALMACEN: sale.DESBLOQUEO_ALMACEN,
        sendMaterial: sale.sendMaterial,
        STATUS_ENV: sale.STATUS_ENV,
        useAdvancedPayment: sale.useAdvancedPayment,
        CLAVE_ANTICIPO: sale.CLAVE_ANTICIPO,
        CANTIDAD_ANTICIPO: sale.CANTIDAD_ANTICIPO,
        FECHA_ENVIO: sale.FECHA_ENVIO,
        clientPickup: sale.clientPickup,
        OC_CLIENTE: sale.OC_CLIENTE,
        USUARIO_CREACION: sale.USUARIO_CREACION,
        USUARIO_CONFIRMACION: sale.USUARIO_CONFIRMACION,
        CLAVE_VEHICULO: sale.CLAVE_VEHICULO,
        CODIGO_ENVIO: sale.CODIGO_ENVIO,
        POS: sale.POS,
        RAZON_SOCIAL: sale.RAZON_SOCIAL,
      };
      return saleInformation;
    },
    async finalMaterialReception(purchaseOrder, status) {
      try {
        let response = await this.$store.dispatch("DELIVERALLMATERIAL", {
          saleId: purchaseOrder._id,
          status: status,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardó exitosamente el nuevo status de envio de material de la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el nuevo status de envio de material de la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el nuevo status de envio de material de la venta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelSale(sale) {
      try {
        let response = await this.$store.dispatch("CANCELSALE", {
          saleId: sale._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al cancelar la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cancelar la venta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateSending(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_ENVIO).getTime() || -Infinity) -
          (new Date(a.FECHA_ENVIO).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_ENVIO).getTime() || -Infinity) -
          (new Date(b.FECHA_ENVIO).getTime() || -Infinity)
        );
      }
    },
    sendSaleArticles(sale, sendAllMaterial = false) {
      let formattedSale = this.formatSale(sale);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendSaleArticles,
        props: {
          saleInformation: formattedSale,
          creationDate: new Date(formattedSale.FECHA),
          sendAllMaterial: sendAllMaterial,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async validateSaleMaterialSubstraction(sale) {
      try {
        let response = await this.$store.dispatch(
          "VALIDATESALEMATERIALSUBSTRACTION",
          {
            saleId: sale._id,
          }
        );
        this.reloadInformation();
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se validó exitosamente el material enviado",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al validar el material enviado en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al validar el material enviado en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    showMaterialReceptions(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SaleDeliveriesDetailsList,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    editMaterialDelivery(materialDelivery) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EditMaterialDeliverySale,
        props: {
          materialDeliveryProp: materialDelivery,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    filteredSales() {
      return this.$store.getters.SALES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }
        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }
        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );
        saleWithProps.formatedSendingDate = "";
        if (singleSale.FECHA_ENVIO) {
          saleWithProps.formatedSendingDate = moment(
            singleSale.FECHA_ENVIO
          ).format("DD-MM-YYYY HH:mm");
        }
        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Parcialmente timbrada";
        } else if (saleWithProps.STATUS_FAC === "PP") {
          saleWithProps.statusFacFormatted = "Pendiente de timbrar";
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
        }

        saleWithProps.clientReceptionFormatted = "No";
        if (saleWithProps.clientPickup) {
          saleWithProps.clientReceptionFormatted = "Si";
          saleWithProps.formatedSendingDate = "Cliente recoge";
        }

        saleWithProps.vehiculeFormatted = "";
        if (saleWithProps.VEHICULO_INFO) {
          saleWithProps.vehiculeFormatted =
            saleWithProps.VEHICULO_INFO.CVE_VEHI;
        }

        let missingTotal = singleSale.TOTAL - singleSale.RESTA;

        saleWithProps.RESTA_FOR = `${toFixedNoRound(
          basicRuleOfThree(singleSale.TOTAL, 100, missingTotal),
          2
        )}%`;

        return saleWithProps;
      }).filter((singleSale) => {
        if (singleSale.STATUS !== "AC" && singleSale.STATUS !== "AN") {
          return false;
        }

        if (
          this.type === 0 &&
          this.companyInformation &&
          this.companyInformation.PERMITIR_ENVIO_SIN_PAGO &&
          singleSale.STATUS_ENV !== "C"
        ) {
          return true;
        }

        if (
          this.type === 0 &&
          singleSale.STATUS_ENV !== "C" &&
          singleSale.VENTA_PARCIAL
        ) {
          return true;
        }

        for (const singleIncome of singleSale.CUENTAS_COB) {
          if (
            singleSale.STATUS_ENV !== "C" &&
            singleIncome.STATUS !== "PA" &&
            singleSale.CONDICION_P !== "R"
          ) {
            return false;
          }
        }

        if (this.type === 0) {
          return (
            singleSale.STATUS_ENV === "P" ||
            !singleSale.STATUS_ENV ||
            singleSale.STATUS_ENV === "I"
          );
        }

        return singleSale.STATUS_ENV === "C";
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
