/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Envio de material</h1>

    <div class="control add-global-controls">
      <b-button type="is-primary" @click="selectAllMissingArticles()"
        >Seleccionar todos los artículos faltantes</b-button
      >

      <b-button type="is-primary" @click="openBarcodeReader()"
        >Leer código de barras</b-button
      >
    </div>

    <div class="invoice-add-item">
      <b-checkbox
        class="permissions-checkbox first-row-checkbox special-margin"
        v-model="newSendMaterial.driverDelivery"
      >
        ¿Crear envio de material para choferes?
      </b-checkbox>
      <b-field
        class="invoice-add-secondary-item-one required-input"
        label-position="inside"
        label="Código aleatorio único (Req)"
      >
        <b-input
          required
          placeholder="123"
          icon="code-brackets"
          v-model="newSendMaterial.CODIGO_ENVIO"
        >
        </b-input>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-one required-input"
        label-position="inside"
        label="Cliente"
      >
        <b-select
          placeholder="Cliente"
          icon="account"
          v-model="saleInformation.CLIENTE"
          disabled
          expanded
        >
          <option value="" disabled>Seleccione un cliente</option>
          <option
            v-for="singleClient in clients"
            :value="singleClient._id"
            :key="singleClient.NOMBRE_EMPRESA"
          >
            {{ singleClient.NOMBRE_EMPRESA }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three"
        label-position="inside"
        label="Folio de venta"
      >
        <b-input
          required
          placeholder="Número de venta"
          type="number"
          step="any"
          icon="pound"
          disabled
          v-model="saleInformation.CLAVE_VENTA"
        >
        </b-input>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three"
        label-position="inside"
        label="Fecha de venta"
      >
        <b-datepicker
          required
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de venta"
          icon="calendar-today"
          v-model="creationDate"
          disabled
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three required-input"
        label-position="inside"
        label="Almacen"
      >
        <b-select
          expanded
          placeholder="Almacen"
          icon="warehouse"
          v-model="saleInformation.ALMACEN"
          disabled
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="singleWarehouse in warehouses"
            :value="singleWarehouse._id"
            :key="singleWarehouse._id"
          >
            {{ singleWarehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div class="invoice-div-bottom-div s">
      <h3 class="form-division">Información general</h3>

      <div class="invoice-payment-info-part">
        <div class="invoice-payment-type-left">
          <strong>Observaciones impresas</strong>
        </div>
        <div class="invoice-payment-type-right">
          <strong>Observaciones internas</strong>
        </div>
      </div>

      <div class="invoice-payment-type-info">
        <div class="invoice-payment-type-info-left invoice-observation">
          <b-input
            type="textarea"
            v-model="newSendMaterial.OBSERVA_IMP"
          ></b-input>
        </div>
        <div class="invoice-payment-type-info-right invoice-observation">
          <b-input type="textarea" v-model="newSendMaterial.OBSERVA"></b-input>
        </div>
      </div>

      <div class="invoice-add-item">
        <b-field
          class="invoice-add-secondary-item-one special-margin"
          label-position="inside"
          label="Recibe (Opc)"
        >
          <b-input
            placeholder="Recibe"
            type="text"
            icon="account-hard-hat"
            v-model="newSendMaterial.RECIBE"
          >
          </b-input>
        </b-field>
      </div>

      <div class="table-header-left">
        Peso total: <b>{{ getTotalWeight() | weight }}</b> <br />
        Peso enviado: <b>{{ getPastShippingWeight() | weight }}</b> <br />
        Peso actual en envio: <b>{{ getCurrentShippingWeight() | weight }}</b>
      </div>

      <b-table
        pagination-position="both"
        :data="saleInformation.currentItems"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="saleItems"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="sale-information-table"
        scrollable
        default-sort="article.DESCRIPCION"
      >
        <template slot-scope="props">
          <b-table-column label="Renglon" field="props.index" sortable>
            <span> {{ props.index + 1 }}</span>
          </b-table-column>

          <b-table-column label="Artículo" field="presentationName" sortable>
            <span>{{ props.row.presentationName }}</span>
          </b-table-column>

          <b-table-column
            label="Clave del artículo"
            field="article.CLAVE_ART"
            sortable
          >
            <span v-if="props.row.article">{{
              props.row.article.CLAVE_ART
            }}</span>
          </b-table-column>

          <b-table-column
            label="Ubicación"
            field="article.DESCRIPCION"
            sortable
          >
            <span>{{
              props.row.article.UBICACION
                ? props.row.article.UBICACION
                : "Sin información"
            }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad total"
            field="quantity"
            sortable
            searchable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantity"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad faltante total"
            field="quantityMissing"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantityMissing"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad enviada total"
            field="quantitySended"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantitySended"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad de nuevo envio"
            field="quantity"
            sortable
          >
            <b-input
              type="number"
              step="any"
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.newReception"
              @input="(value) => registerNewSend(value, props.row)"
            ></b-input>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="sendSaleArticles('C')"
        :disabled="buttonDisabled"
        >Enviar artículos</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PrintMaterialDelivery from "../../Global/components/print-formats/PrintMaterialDelivery";
import moment from "moment";
import {
  checkValidationErrors,
  isNumber,
  round,
  validateFields,
} from "@/utils/fns";
import BarcodeReaderModal from "@/modules/Sales/components/BarcodeReaderModal.vue";
import { EventBus } from "@/event-bus";
export default {
  name: "SendSaleArticles",
  props: ["saleInformation", "creationDate", "sendAllMaterial"],
  components: {},
  data() {
    return {
      // Table values
      newSendMaterial: {
        NUM_VENTA: "",
        VENTA: "",
        ALMACEN: "",
        CLIENTE: "",
        OBSERVA: "",
        OBSERVA_IMP: "",
        RECIBE: "",
        CODIGO_ENVIO: "",
        driverDelivery: false,
      },
      sendMaterialRequiredErrors: {},
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      buttonDisabled: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    if (this.sendAllMaterial) {
      this.selectAllMissingArticles();
    }

    if (this.saleInformation.OBSERVA) {
      this.newSendMaterial.OBSERVA = this.saleInformation.OBSERVA;
    }
    if (this.saleInformation.OBSERVA_IMP) {
      this.newSendMaterial.OBSERVA_IMP = this.saleInformation.OBSERVA_IMP;
    }
    this.newSendMaterial.NUM_VENTA = this.saleInformation.CLAVE_VENTA;
    this.newSendMaterial.VENTA = this.saleInformation._id;
    this.newSendMaterial.ALMACEN = this.saleInformation.ALMACEN;
    this.newSendMaterial.CLIENTE = this.saleInformation.CLIENTE;
    this.newSendMaterial.CLIENTE = this.saleInformation.CLIENTE;

    EventBus.$on("barcodeRead", async (barCode) => {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.saleInformation.currentItems.entries()) {
        if (singleItem.article.CODIGO_BARRAS === barCode.code) {
          this.saleInformation.currentItems[singleItemIndex].newReception
            ? (this.saleInformation.currentItems[
                singleItemIndex
              ].newReception += 1)
            : (this.saleInformation.currentItems[
                singleItemIndex
              ].newReception = 1);
          this.registerNewSend(
            this.saleInformation.currentItems[singleItemIndex].newReception,
            this.saleInformation.currentItems[singleItemIndex]
          );
        }
      }
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getAllDoneTransfers() {
      let total = 0;
      let subtotal = 0;
      let iva = 0;
      let ieps = 0;
      let cost = 0;
      let discount = 0;

      let transfersToDo = [];

      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.saleInformation.currentItems.entries()) {
        if (
          singleArticle.newReception < 0 &&
          (!this.companyPaymentPlanModules ||
            this.companyPaymentPlanModules.sales.sendMaterialNegative !== "S" ||
            !this.permissions ||
            this.permissions.sales.sendMaterialNegative !== "S")
        ) {
          continue;
        }

        if (isNumber(singleArticle.newReception)) {
          let costItem = 0;
          let discountItem = 0;
          let totalIvaItem = 0;
          let totalIepsItem = 0;
          let subTotalItem = 0;

          let priceQuantity = singleArticle.price * singleArticle.newReception;

          for (const cost of singleArticle.costs) {
            costItem += cost.CANTIDAD * singleArticle.CANTIDAD_PRESENTACION;
          }

          cost += singleArticle.newReception * costItem;

          for (const discount of singleArticle.discounts) {
            discountItem +=
              discount.CANTIDAD * singleArticle.CANTIDAD_PRESENTACION;
          }

          discount += singleArticle.newReception * discountItem;

          subTotalItem =
            priceQuantity +
            singleArticle.newReception * costItem -
            singleArticle.newReception * discountItem;

          subtotal += priceQuantity;

          let totalItem = 0;
          totalItem += subTotalItem;

          if (singleArticle.iva > 0) {
            totalIvaItem = subTotalItem * (singleArticle.iva / 100);
            totalItem += totalIvaItem;
            iva += totalIvaItem;
          }

          if (singleArticle.ieps > 0) {
            totalIepsItem = subTotalItem * (singleArticle.ieps / 100);
            totalItem += totalIepsItem;
            ieps += totalIepsItem;
          }

          transfersToDo.push({
            ...singleArticle,
            article: singleArticle.article._id,
            name: singleArticle.article.DESCRIPCION,
            key: singleArticle.article.CLAVE_ART,
            IMP_COST: costItem,
            IMP_DESC: discountItem,
            TOTAL: totalItem,
            SUB_TOT: subTotalItem,
            IVA_GTO: totalIvaItem,
            IEPS_GTO: totalIepsItem,
            DESC_GTO: discountItem * singleArticle.newReception,
            COST_GTO: costItem * singleArticle.newReception,
          });
        }
      }

      for (let singleCost of this.saleInformation.COSTOS) {
        if (singleCost.PORCENTAJE) {
          let costQuantity = subtotal * (singleCost.PORCENTAJE / 100);
          cost += costQuantity;
          cost += costQuantity;
          iva += costQuantity * 0.16;
        }
      }

      for (let singleDiscount of this.saleInformation.DESCS) {
        if (singleDiscount.PORCENTAJE) {
          let discountQuantity = subtotal * (singleDiscount.PORCENTAJE / 100);
          discount += discountQuantity;
          iva -= discountQuantity * 0.16;
        }
      }

      total += subtotal - discount + cost + iva + ieps;

      this.newSendMaterial.IVA = round(Number(iva).toFixed(5), 2);
      this.newSendMaterial.IEPS = round(Number(ieps).toFixed(5), 2);
      this.newSendMaterial.DESC = this.saleInformation.DESCS;
      this.newSendMaterial.COSTOS = this.saleInformation.COSTOS;
      this.newSendMaterial.DESC_GTO = round(Number(discount).toFixed(5), 2);
      this.newSendMaterial.COST_GTO = round(Number(cost).toFixed(5), 2);
      this.newSendMaterial.SUB_TOTAL = round(Number(subtotal).toFixed(5), 2);
      this.newSendMaterial.TOTAL = round(Number(total).toFixed(5), 2);
      return transfersToDo;
    },
    checkValidQuantities() {
      for (const singleArticle of this.saleInformation.currentItems) {
        if (Number(singleArticle.quantityMissing) < 0) {
          return true;
        }
      }

      return false;
    },
    async sendSaleArticles() {
      try {
        this.buttonDisabled = true;
        if (!this.validateSendCode()) {
          this.buttonDisabled = false;
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el envio de material el código aleatorio único no coincide",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.articleRequiredErrors = validateFields(
          this.newSendMaterial,
          this.sendMaterialRequiredErrors
        );
        if (checkValidationErrors(this.sendMaterialRequiredErrors)) {
          if (this.checkValidQuantities()) {
            this.buttonDisabled = false;
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el envio de artículos, el envio es mayor a la cantidad solicitada",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }
          let itemsToTranfer = this.getAllDoneTransfers();
          this.newSendMaterial.items = itemsToTranfer;
          if (itemsToTranfer.length > 0) {
            let response = await this.$store.dispatch(
              "SENDMATERIAL",
              this.newSendMaterial
            );
            if (response && response.delivery) {
              this.buttonDisabled = false;
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se guardó exitosamente el envio de material",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  EventBus.$emit("refetchMaterialDeliveries");
                  this.showPrintOrSendMaterialDelivery(
                    response.saleInformation.ENVIOS_MATERIAL[
                      response.saleInformation.ENVIOS_MATERIAL.length - 1
                    ],
                    response.saleInformation
                  );
                },
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.buttonDisabled = false;
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al guardar el envio de material en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
            this.closeModal();
          } else {
            this.buttonDisabled = false;
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error, no hay artículos que enviar",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.buttonDisabled = false;
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el envio de material en la base de datos revise los campos Requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.buttonDisabled = false;
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el envio de material en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    showPrintOrSendMaterialDelivery(deliveryInformation, saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintMaterialDelivery,
        props: {
          saleInformation: saleInformation,
          deliveryInormation: deliveryInformation,
          title: "Envio de material",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    registerNewSend(value, article) {
      article.quantityMissing =
        Number(article.quantityMissingOriginal) - Number(value);
      article.quantitySended =
        Number(article.quantitySendedOriginal) + Number(value);
    },
    selectAllMissingArticles() {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.saleInformation.currentItems.entries()) {
        this.saleInformation.currentItems[singleArticleIndex].newReception =
          singleArticle.quantityMissingOriginal;
        this.registerNewSend(
          singleArticle.quantityMissingOriginal,
          singleArticle
        );
      }
    },
    getTotalWeight() {
      let totalWeight = 0;

      for (const singleItem of this.saleInformation.currentItems) {
        if (singleItem.article && singleItem.article.CANT_PESO) {
          totalWeight +=
            Number(singleItem.quantity) * Number(singleItem.article.CANT_PESO);
        }
      }

      return totalWeight;
    },
    getCurrentShippingWeight() {
      let totalWeight = 0;

      for (const singleItem of this.saleInformation.currentItems) {
        if (singleItem.newReception) {
          if (singleItem.article && singleItem.article.CANT_PESO) {
            totalWeight +=
              Number(singleItem.newReception) *
              Number(singleItem.article.CANT_PESO);
          }
        }
      }

      return totalWeight;
    },
    getPastShippingWeight() {
      let totalWeight = 0;

      for (const singleItem of this.saleInformation.currentItems) {
        if (singleItem.quantitySended) {
          if (singleItem.article && singleItem.article.CANT_PESO) {
            totalWeight +=
              Number(singleItem.quantitySended) *
              Number(singleItem.article.CANT_PESO);
          }
        }
      }

      return totalWeight;
    },
    validateSendCode() {
      if (!this.saleInformation.CODIGO_ENVIO) {
        return true;
      }

      return (
        this.newSendMaterial.CODIGO_ENVIO === this.saleInformation.CODIGO_ENVIO
      );
    },
    /**
     *
     * @desc open the barcode reader
     */
    openBarcodeReader() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: BarcodeReaderModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    lastWarehouseTransferNumber() {
      return this.$store.getters.LASTWAREHOUSETRANSFERNUMBER;
    },
    articles() {
      return this.$store.getters.ARTICLES;
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    lastExpenseNumber() {
      return this.$store.getters.LASTEXPENSENUMBER;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    lastExpenseNumber() {
      this.newSendMaterial.NUM_FOLIO = this.lastExpenseNumber;
    },
    lastWarehouseTransferNumber() {
      this.newSendMaterial.CLAVE_TRAN = this.lastWarehouseTransferNumber;
    },
  },
  filters: {
    weight(value) {
      value = value.toString();
      return `${value} Kg`;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
