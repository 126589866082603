<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Editar envio de material</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-checkbox
      class="permissions-checkbox first-row-checkbox special-margin"
      v-model="marterialDeliveryInformation.clientPickup"
    >
      El cliente recoge el material
    </b-checkbox>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de recepción o recolección (Opcional)"
        expanded
        label-position="inside"
      >
        <b-datetimepicker
          :datetime-formatter="
            (date) => moment(date).format('DD/MM/YYYY HH:mm')
          "
          :datepicker="datepicker"
          v-model="marterialDeliveryInformation.FECHA_ENVIO"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-second"
        label="Vehiculo (Opc)"
        expanded
        label-position="inside"
      >
        <b-select
          placeholder="Ejemplo: Camioneta"
          icon="truck"
          expanded
          v-model="marterialDeliveryInformation.CLAVE_VEHICULO"
        >
          <option :value="null">Seleccione un vehiculo</option>
          <option
            v-for="singleVehicule in vehicules"
            :value="singleVehicule._id"
            :key="singleVehicule._id"
          >
            {{ singleVehicule.CVE_VEHI }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="editMaterialDelivery()"
        >Editar envio de material</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { openConfirmationModalSucces } from "../../../utils/component-fns";
import moment from "moment";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "EditMaterialDeliverySale",
  props: ["materialDeliveryProp"],
  components: {},
  directives: { cleave },
  data() {
    return {
      marterialDeliveryInformation: {
        FECHA_ENVIO: undefined,
        clientPickup: false,
        CLAVE_VEHICULO: undefined,
      },
      moment: moment,
      datepicker: {
        "month-names": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        "day-names": ["D", "L", "M", "M", "J", "V", "S"],
        "show-week-number": true,
        locale: "es-MX",
      },
    };
  },
  mounted() {
    if (this.materialDeliveryProp.FECHA_ENVIO) {
      this.marterialDeliveryInformation.FECHA_ENVIO = new Date(
        this.materialDeliveryProp.FECHA_ENVIO
      );
    }

    if (this.materialDeliveryProp.clientPickup) {
      this.marterialDeliveryInformation.clientPickup =
        this.materialDeliveryProp.clientPickup;
    }

    if (this.materialDeliveryProp.CLAVE_VEHICULO) {
      this.marterialDeliveryInformation.CLAVE_VEHICULO =
        this.materialDeliveryProp.CLAVE_VEHICULO;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc edits a material delivery sale
     */
    async editMaterialDelivery() {
      try {
        let response = await this.$store.dispatch("EDITMATERIALDELIVERYSALE", {
          saleId: this.materialDeliveryProp._id,
          newInformation: this.marterialDeliveryInformation,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente el envio de material",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error el envio de material",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el envio de material",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    vehicules() {
      const vehiculesWithProps = [...this.$store.getters.VEHICULES];
      return vehiculesWithProps;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
