/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Detalles de envio de material</h1>

    <div class="invoice-add-item">
      <b-field
        class="invoice-add-secondary-item-one required-input"
        label-position="inside"
        label="Cliente"
      >
        <b-select
          placeholder="Cliente"
          icon="account"
          :value="deliveryMaterial.CLAVE_CLIENTE._id"
          disabled
          expanded
        >
          <option value="" disabled>Seleccione un cliente</option>
          <option
            v-for="singleClient in clients"
            :value="singleClient._id"
            :key="singleClient.NOMBRE_EMPRESA"
          >
            {{ singleClient.NOMBRE_EMPRESA }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three"
        label-position="inside"
        label="Folio de venta"
      >
        <b-input
          required
          placeholder="Número de venta"
          type="number"
          step="any"
          icon="pound"
          disabled
          :value="deliveryMaterial.NUM_VENTA"
        >
        </b-input>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three"
        label-position="inside"
        label="Fecha de venta"
      >
        <b-datepicker
          required
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de venta"
          icon="calendar-today"
          :value="creationDate"
          disabled
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three required-input"
        label-position="inside"
        label="Almacen"
      >
        <b-select
          expanded
          placeholder="Almacen"
          icon="warehouse"
          :value="deliveryMaterial.CLAVE_ALMACEN._id"
          disabled
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse._id"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div class="invoice-div-bottom-div s">
      <h3 class="form-division">Información general</h3>

      <div class="invoice-payment-info-part">
        <div class="invoice-payment-type-left">
          <strong>Observaciones impresas</strong>
        </div>
        <div class="invoice-payment-type-right">
          <strong>Observaciones internas</strong>
        </div>
      </div>

      <div class="invoice-payment-type-info">
        <div class="invoice-payment-type-info-left invoice-observation">
          <b-input
            type="textarea"
            v-model="deliveryMaterial.OBSERVA_IMP"
          ></b-input>
        </div>
        <div class="invoice-payment-type-info-right invoice-observation">
          <b-input type="textarea" v-model="deliveryMaterial.OBSERVA"></b-input>
        </div>
      </div>

      <div class="invoice-add-item">
        <b-field
          class="invoice-add-secondary-item-one special-margin"
          label-position="inside"
          label="Recibe (Opc)"
        >
          <b-input
            placeholder="Recibe"
            type="text"
            icon="account-hard-hat"
            v-model="deliveryMaterial.RECIBE"
          >
          </b-input>
        </b-field>
      </div>

      <b-table
        pagination-position="both"
        :data="deliveryMaterial.ENVIO_DETALLES"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="saleItems"
        custom-row-key="DESCRIBEAR"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="sale-information-table"
        scrollable
        default-sort="DESCRIBEAR"
      >
        <template slot-scope="props">
          <b-table-column label="Renglon" field="props.index" sortable>
            <span> {{ props.index + 1 }}</span>
          </b-table-column>

          <b-table-column label="Artículo" field="DESCRIBEAR" sortable>
            <span>{{ props.row.DESCRIBEAR }}</span>
          </b-table-column>

          <b-table-column label="Clave del artículo" field="CONCEP_IE" sortable>
            <span>{{ props.row.CONCEP_IE }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad total"
            field="CANTIDAD_TOTAL"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.CANTIDAD_TOTAL"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Cantidad enviada" field="CANTIDAD" sortable>
            <b-input
              type="number"
              step="any"
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.CANTIDAD"
              disabled
            ></b-input>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
export default {
  name: "SendArticleDetails",
  props: ["deliveryMaterial", "creationDate"],
  components: {},
  data() {
    return {
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
