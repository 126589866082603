<template>
  <div class="container add-global-modal-main-container ticket-main-container">
    <h1 class="add-global-modal-main-title">Descargar carta porte</h1>

    <div
      style="width: 90%; margin-left: 5%; margin-right: 5%"
      ref="porteCard"
      class="porte-card-container"
    >
      <div style="display: flex; justify-content: space-between">
        <div style="width: 100%; text-align: start">
          <h1 style="padding-top: 3pt; text-indent: 0pt; text-align: left">
            {{ companyInformation.NOMBRE_FAC }}
          </h1>
          <h2 style="padding-top: 1pt; text-indent: 0pt; text-align: left">
            {{ companyInformation.RFC }}
          </h2>
          <p
            class="s1"
            style="padding-top: 4pt; text-indent: 0pt; text-align: left"
          >
            {{ companyInformation.DOMICIL }}
            {{ companyInformation.NOMBRE_MUNICIPIO }},
            {{ companyInformation.ESTADO }}
            {{ companyInformation.CP }}
          </p>
          <br />
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 5px;
        "
      >
        <div style="width: 50%; text-align: start">
          <p
            class="s2"
            style="padding-top: 4pt; text-indent: 0pt; text-align: left"
            v-if="companyInformation.TELS"
          >
            Teléfono:{{ companyInformation.TELS }}
          </p>
          <p class="s2" style="text-indent: 0pt; text-align: left">
            Regimen Fiscal:{{
              xml["cfdi:Comprobante"][0]["cfdi:Emisor"][0].RegimenFiscal
            }}
          </p>
          <p style="text-indent: 0pt; text-align: left">
            LUGAR DE EXPEDICION:
            {{ xml["cfdi:Comprobante"][0].LugarExpedicion }}
          </p>
        </div>
        <div style="width: 50%; text-align: start">
          <p class="s3" style="text-align: end">TRASLADO</p>
          <p class="s4" style="text-align: end">
            Fecha:
            {{ xml["cfdi:Comprobante"][0].Fecha }}
          </p>
        </div>
      </div>

      <div>
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-bottom: 5px">
        <p
          class="s1"
          style="padding-top: 3pt; text-indent: 0pt; text-align: left"
        >
          CLIENTE: {{ xml["cfdi:Comprobante"][0]["cfdi:Receptor"][0].Nombre }}
        </p>
        <p class="s1" style="text-indent: 0pt; text-align: left">
          RFC:
          {{ xml["cfdi:Comprobante"][0]["cfdi:Receptor"][0].Rfc }}
        </p>
      </div>

      <div style="padding-bottom: 5px" class="section-container">
        <table style="border-collapse: collapse" cellspacing="0">
          <tr style="height: 17pt">
            <td
              style="
                width: 44pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s5"
                style="
                  padding-top: 4pt;
                  padding-left: 1pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                CANTIDAD
              </p>
            </td>
            <td
              style="
                width: 59pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 14pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                UNIDAD
              </p>
            </td>
            <td
              style="
                width: 73pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s5"
                style="
                  padding-top: 4pt;
                  padding-left: 1pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                NO.IDENTIFICACION
              </p>
            </td>
            <td
              style="
                width: 273pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 1pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                DESCRIPCION
              </p>
            </td>
            <td
              style="
                width: 55pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 4pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                P. UNITARIO
              </p>
            </td>
            <td
              style="
                width: 78pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 40pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                IMPORTE
              </p>
            </td>
          </tr>
        </table>
        <table style="border-collapse: collapse" cellspacing="0">
          <tr
            style="height: 11pt"
            v-for="singleItem in xml['cfdi:Comprobante'][0][
              'cfdi:Conceptos'
            ][0]['cfdi:Concepto']"
            :key="singleItem.ClaveProdServ"
          >
            <td style="width: 44pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.Cantidad }}
              </p>
            </td>
            <td style="width: 59pt">
              <p style="text-indent: 0pt; text-align: center">
                {{ singleItem.ClaveUnidad }}
              </p>
            </td>
            <td style="width: 73pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.NoIdentificacion }}
              </p>
            </td>
            <td style="width: 273pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.ClaveProdServ }} {{ singleItem.Descripcion }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <table
        style="border-collapse: collapse"
        cellspacing="0"
        class="section-container"
      >
        <tr style="height: 11pt">
          <td
            style="
              width: 427pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s7"
              style="
                padding-left: 1pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
              "
            >
              ( 00/100 XXX)
            </p>
          </td>
          <td
            style="
              width: 84pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="
                padding-left: 1pt;
                text-indent: 0pt;
                line-height: 12pt;
                text-align: left;
              "
            >
              SUBTOTAL
            </p>
          </td>
          <td
            style="
              width: 72pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="text-indent: 0pt; line-height: 10pt; text-align: right"
            >
              0.00
            </p>
          </td>
        </tr>
        <tr style="height: 14pt">
          <td
            style="
              width: 427pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
            rowspan="2"
          >
            <p
              class="s9"
              style="
                padding-top: 4pt;
                padding-left: 6pt;
                text-indent: 0pt;
                text-align: left;
                padding-bottom: 5px;
              "
            >
              EFECTOS FISCALES AL PAGO
            </p>
            <p
              class="s9"
              style="
                padding-left: 6pt;
                text-indent: 0pt;
                text-align: left;
                padding-bottom: 5px;
              "
            >
              MONEDA:{{ xml["cfdi:Comprobante"][0].Moneda }}
            </p>
            <p
              class="s9"
              style="
                padding-left: 7pt;
                text-indent: 0pt;
                text-align: left;
                padding-bottom: 5px;
              "
            >
              USO CFDI:{{
                xml["cfdi:Comprobante"][0]["cfdi:Receptor"][0].UsoCFDI
              }}
            </p>
          </td>
          <td
            style="
              width: 84pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="
                padding-top: 1pt;
                padding-left: 1pt;
                text-indent: 0pt;
                text-align: left;
              "
            >
              I.V.A
            </p>
          </td>
          <td
            style="
              width: 72pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="padding-top: 1pt; text-indent: 0pt; text-align: right"
            >
              0.00
            </p>
          </td>
        </tr>
        <tr style="height: 13pt">
          <td
            style="
              width: 84pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="
                padding-left: 1pt;
                text-indent: 0pt;
                line-height: 11pt;
                text-align: left;
              "
            >
              TOTAL
            </p>
          </td>
          <td
            style="
              width: 72pt;
              border-top-style: solid;
              border-top-width: 1pt;
              border-left-style: solid;
              border-left-width: 1pt;
              border-bottom-style: solid;
              border-bottom-width: 1pt;
              border-right-style: solid;
              border-right-width: 1pt;
            "
          >
            <p
              class="s8"
              style="text-indent: 0pt; line-height: 11pt; text-align: right"
            >
              0.00
            </p>
          </td>
        </tr>
      </table>

      <div style="padding-bottom: 5px">
        <br />
        <p
          class="s9"
          style="padding-left: 6pt; text-indent: 0pt; text-align: left"
        >
          IMPUESTO RETENIDO DE CONFORMIDAD CON LA LEY DEL IMPUESTO AL VALOR
          AGREGADO
        </p>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          CFDIS RELACIONADOS
        </h3>
      </div>

      <div
        class="section-container"
        v-for="singleRelation in xml['cfdi:Comprobante'][0][
          'cfdi:CfdiRelacionados'
        ]"
        :key="singleRelation.TipoRelacion"
      >
        <p
          style="
            padding-bottom: 1pt;
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
          "
        >
          TIPO DE RELACION: {{ singleRelation.TipoRelacion }}
        </p>
        <p
          style="
            padding-top: 2pt;
            padding-bottom: 1pt;
            padding-left: 6pt;
            text-indent: 0pt;
            text-align: left;
          "
          v-for="singleInvoice in singleRelation['cfdi:CfdiRelacionado']"
          :key="singleInvoice.UUID"
        >
          UUID:{{ singleInvoice.UUID }}
        </p>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          CARTA PORTE
        </h3>
      </div>

      <div class="section-container">
        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Versión:{{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "cartaporte30:CartaPorte"
            ][0].Version
          }}
        </p>
        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Transporte Internacional:{{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "cartaporte30:CartaPorte"
            ][0].TranspInternac
          }}
        </p>
        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Total Distancia:{{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "cartaporte30:CartaPorte"
            ][0].TotalDistRec
          }}
          Kms.
        </p>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          UBICACIONES
        </h3>
      </div>

      <div
        v-for="singleLocation in xml['cfdi:Comprobante'][0][
          'cfdi:Complemento'
        ][0]['cartaporte30:CartaPorte'][0]['cartaporte30:Ubicaciones'][0][
          'cartaporte30:Ubicacion'
        ]"
        :key="singleLocation.IDUbicacion"
      >
        <div style="padding-top: 5px; padding-bottom: 5px">
          <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
            {{
              singleLocation.TipoUbicacion === "Origen" ? "Origen" : "Destino"
            }}
          </h3>
        </div>

        <div
          style="display: flex; flex-direction: row"
          class="section-container"
        >
          <div style="width: 50%">
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
              "
            >
              {{
                singleLocation.TipoUbicacion === "Origen"
                  ? "Salida:"
                  : "Lllegada:"
              }}{{ singleLocation.FechaHoraSalidaLlegada }}
            </p>

            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
              "
              v-if="singleLocation.DistanciaRecorrida"
            >
              Distancia:{{ singleLocation.DistanciaRecorrida }} Kms.
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
              "
            >
              RFC:{{ singleLocation.RFCRemitenteDestinatario }}
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              Domicilio:{{ singleLocation["cartaporte30:Domicilio"][0].Calle }}
              {{ singleLocation["cartaporte30:Domicilio"][0].NumeroInterior }}
              ({{ singleLocation["cartaporte30:Domicilio"][0].Referencia }})
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              Colonia:{{ singleLocation["cartaporte30:Domicilio"][0].Colonia }}
            </p>
          </div>
          <div style="width: 50%; text-align: end">
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              Localidad:{{
                singleLocation["cartaporte30:Domicilio"][0].Localidad
              }}
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              Municipio:{{
                singleLocation["cartaporte30:Domicilio"][0].Municipio
              }}
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              Estado:{{ singleLocation["cartaporte30:Domicilio"][0].Estado }}
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              País:{{ singleLocation["cartaporte30:Domicilio"][0].Pais }}
            </p>
            <p
              style="
                padding-left: 5pt;
                text-indent: 0pt;
                line-height: 9pt;
                text-align: left;
                padding-bottom: 1pt;
                text-align: start;
              "
            >
              Cod.Postal:{{
                singleLocation["cartaporte30:Domicilio"][0].CodigoPostal
              }}
            </p>
          </div>
        </div>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          MERCANCIAS
        </h3>
      </div>

      <div style="padding-bottom: 5px" class="section-container">
        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Total Mercancías:
          {{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "cartaporte30:CartaPorte"
            ][0]["cartaporte30:Mercancias"][0].NumTotalMercancias
          }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Peso Bruto Total:{{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "cartaporte30:CartaPorte"
            ][0]["cartaporte30:Mercancias"][0].PesoBrutoTotal
          }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Unidad Peso:{{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "cartaporte30:CartaPorte"
            ][0]["cartaporte30:Mercancias"][0].UnidadPeso
          }}
        </p>
      </div>

      <div style="padding-bottom: 5px" class="section-container">
        <table style="border-collapse: collapse" cellspacing="0">
          <tr style="height: 17pt">
            <td
              style="
                width: 64pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s5"
                style="
                  padding-top: 4pt;
                  padding-left: 1pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                Cve.Producto
              </p>
            </td>
            <td
              style="
                width: 250pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 14pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                Descripción
              </p>
            </td>
            <td
              style="
                width: 73pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s5"
                style="
                  padding-top: 4pt;
                  padding-left: 1pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                Cantidad
              </p>
            </td>
            <td
              style="
                width: 59pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 1pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                Cve.Unidad
              </p>
            </td>
            <td
              style="
                width: 55pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 4pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                Peso
              </p>
            </td>
            <td
              style="
                width: 78pt;
                border-top-style: solid;
                border-top-width: 1pt;
                border-left-style: solid;
                border-left-width: 1pt;
                border-bottom-style: solid;
                border-bottom-width: 1pt;
                border-right-style: solid;
                border-right-width: 1pt;
              "
            >
              <p
                class="s6"
                style="
                  padding-top: 3pt;
                  padding-left: 0pt;
                  text-indent: 0pt;
                  text-align: center;
                "
              >
                Peligroso
              </p>
            </td>
          </tr>
        </table>
        <table style="border-collapse: collapse" cellspacing="0">
          <tr
            style="height: 11pt"
            v-for="singleItem in xml['cfdi:Comprobante'][0][
              'cfdi:Complemento'
            ][0]['cartaporte30:CartaPorte'][0]['cartaporte30:Mercancias'][0][
              'cartaporte30:Mercancia'
            ]"
            :key="singleItem.Descripcion"
          >
            <td style="width: 64pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.BienesTransp }}
              </p>
            </td>
            <td style="width: 250pt">
              <p style="text-indent: 0pt; text-align: center">
                {{ singleItem.Descripcion }}
              </p>
            </td>
            <td style="width: 73pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.Cantidad }}
              </p>
            </td>
            <td style="width: 59pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.ClaveUnidad }}
              </p>
            </td>
            <td style="width: 55pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.PesoEnKg }}
              </p>
            </td>
            <td style="width: 78pt">
              <p
                class="s7"
                style="text-indent: 0pt; line-height: 9pt; text-align: center"
              >
                {{ singleItem.MaterialPeligroso }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          AUTOTRANSPORTE
        </h3>
      </div>

      <div
        style="display: flex; flex-direction: row; padding-bottom: 5px"
        class="section-container"
      >
        <div style="width: 50%">
          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
            v-if="
              xml['cfdi:Comprobante'][0]['cfdi:Complemento'][0][
                'cartaporte30:CartaPorte'
              ][0]['cartaporte30:Mercancias'][0][
                'cartaporte30:Autotransporte'
              ][0].PermSCT
            "
          >
            Tipo Permiso:{{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0].PermSCT
            }}
          </p>

          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
            v-if="
              xml['cfdi:Comprobante'][0]['cfdi:Complemento'][0][
                'cartaporte30:CartaPorte'
              ][0]['cartaporte30:Mercancias'][0][
                'cartaporte30:Autotransporte'
              ][0].NumPermisoSCT
            "
          >
            Permiso Número:
            {{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0].NumPermisoSCT
            }}
          </p>

          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
          >
            Compañía Aseguradora:{{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0]["cartaporte30:Seguros"][0].AseguraRespCivil
            }}
          </p>

          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
          >
            Póliza:{{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0]["cartaporte30:Seguros"][0].PolizaRespCivil
            }}
          </p>
        </div>
        <div style="width: 50%; text-align: end">
          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
          >
            Config.Vehicular:{{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0]["cartaporte30:IdentificacionVehicular"][0].ConfigVehicular
            }}
          </p>

          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
          >
            Modelo:{{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0]["cartaporte30:IdentificacionVehicular"][0].AnioModeloVM
            }}
          </p>

          <p
            style="
              padding-left: 5pt;
              text-indent: 0pt;
              line-height: 9pt;
              text-align: start;
              padding-bottom: 1pt;
            "
          >
            Placas:{{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "cartaporte30:CartaPorte"
              ][0]["cartaporte30:Mercancias"][0][
                "cartaporte30:Autotransporte"
              ][0]["cartaporte30:IdentificacionVehicular"][0].PlacaVM
            }}
          </p>
        </div>
      </div>

      <div style="padding-bottom: 5px; padding-top: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <h3 style="padding-left: 5pt; text-indent: 0pt; text-align: left">
          FIGURA TRANSPORTE
        </h3>
      </div>

      <div
        style="padding-bottom: 5px"
        class="section-container"
        v-for="singleFigure in xml['cfdi:Comprobante'][0][
          'cfdi:Complemento'
        ][0]['cartaporte30:CartaPorte'][0]['cartaporte30:FiguraTransporte'][0][
          'cartaporte30:TiposFigura'
        ]"
        :key="singleFigure.RFCFigura"
      >
        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Tipo:{{ singleFigure.TipoFigura }}
        </p>

        <p
          v-if="singleFigure.NombreFigura"
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Operador:{{ singleFigure.NombreFigura }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          RFC:{{ singleFigure.RFCFigura }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          Licencia:{{ singleFigure.NumLicencia }}
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div class="section-container">
        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          FOLIO FISCAL:
          {{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "tfd:TimbreFiscalDigital"
            ][0].UUID
          }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          FECHA CERTIFICACION:
          {{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "tfd:TimbreFiscalDigital"
            ][0].FechaTimbrado
          }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          CERTIFICADO EMISOR:
          {{ xml["cfdi:Comprobante"][0].NoCertificado }}
        </p>

        <p
          style="
            padding-left: 5pt;
            text-indent: 0pt;
            line-height: 9pt;
            text-align: left;
            padding-bottom: 1pt;
          "
        >
          CERTIFICADO SAT:
          {{
            xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
              "tfd:TimbreFiscalDigital"
            ][0].NoCertificadoSAT
          }}
        </p>
      </div>

      <div style="padding-top: 5px; padding-bottom: 5px">
        <p style="text-indent: 0pt; text-align: left">
          <span>
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>
                  <img
                    width="778"
                    height="1"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwoAAAABCAYAAAB+F1l/AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAKElEQVRIie3OMQEAIAwDsPHXv9L+8OBhT6IgZ2buAAB8Sdo22w9g1wNRMQUBLO/2+gAAAABJRU5ErkJgggAA"
                  />
                </td>
              </tr>
            </table>
          </span>
        </p>
      </div>

      <div
        class="section-container"
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-top: 5px;
        "
      >
        <div
          style="
            width: 29%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <img width="200" :src="`data:image/png;base64,${qrCode}`" />
          <p style="text-indent: 0pt; text-align: start">
            ESTE DOCUMENTO ES UNA REPRESENTACION IMPRESA DE UN CFDI
          </p>
        </div>
        <div style="width: 69%">
          <p style="text-indent: 0pt; text-align: left; word-break: break-all">
            SELLO CFDI:
            {{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "tfd:TimbreFiscalDigital"
              ][0].SelloCFD
            }}
          </p>
          <br />
          <p style="text-indent: 0pt; text-align: left; word-break: break-all">
            SELLO SAT:
            {{
              xml["cfdi:Comprobante"][0]["cfdi:Complemento"][0][
                "tfd:TimbreFiscalDigital"
              ][0].SelloSAT
            }}
          </p>
          <br />
          <p style="text-indent: 0pt; text-align: left; word-break: break-all">
            CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACION DEL SAT:
            {{ satString }}
          </p>
        </div>
      </div>
    </div>

    <div class="porte-card-print-action special-margin">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print()"
          >Descargar carta porte</b-button
        >

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment-timezone";
// @ is an alias to /src
export default {
  name: "PrintPorteCard",
  props: ["qrCode", "xml", "satString", "name"],
  components: {},
  data() {
    return {
      moment: moment,
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print() {
      await this.createPdfHtml();
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 1000);
    },
    async createPdfHtml() {
      let pdfOptions = {
        filename: `CartaPorte.pdf`,
        pagebreak: { avoid: [".section-container"] },
        scale: 2,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0, 0, 0],
      };
      let result = await html2pdf()
        .set(pdfOptions)
        .from(this.$refs.porteCard)
        .save();
      return result;
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";

.porte-card-container h1 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
}

.porte-card-container h2 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}

.porte-card-container .s1 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 9.5pt;
}

.porte-card-container .s2 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 9pt;
}

.porte-card-container .p,
p {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
  margin: 0pt;
}

.porte-card-container .s3 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

.porte-card-container .s4 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 10pt;
}

.porte-card-container .s5 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 7pt;
}

.porte-card-container .s6 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 8pt;
}

.porte-card-container .s7 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
}

.porte-card-container .s8 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 9.5pt;
}

.porte-card-container .s9 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 7pt;
}

.porte-card-container h3 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 9.5pt;
}

.porte-card-container h4 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 8pt;
}

.porte-card-container .s10 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 7pt;
  vertical-align: -2pt;
}

.porte-card-container .s11 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
}

.porte-card-container .s12 {
  color: black;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 8pt;
  vertical-align: 1pt;
}

.porte-card-print-action {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
</style>
