<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Cartas porte</h1>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de cartas porte:
        <b>{{ currentObjects.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentObjects"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="porteCards"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de carta porte`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay cartas porte</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio" field="NUM_FOLIO" sortable>
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus de timbrado"
            field="STATUS_FAC"
            sortable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FAC)">{{
              "Sin información"
            }}</span>

            <span v-if="props.row.STATUS_FAC === 'SF'">Sin Timbrar</span>
            <span v-if="props.row.STATUS_FAC === 'TI'">Timbrada</span>
            <span v-if="props.row.STATUS_FAC === 'CA'">Cancelada</span>
          </b-table-column>

          <b-table-column label="Fecha" field="formatedDate" sortable>
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.formatedDate)">{{
              props.row.formatedDate
            }}</span>
          </b-table-column>

          <b-table-column
            label="Distancia total"
            field="DISTANCIA_TOTAL"
            sortable
          >
            <span v-if="!checkIfValid(props.row.DISTANCIA_TOTAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.DISTANCIA_TOTAL)">{{
              props.row.DISTANCIA_TOTAL
            }}</span>
          </b-table-column>

          <b-table-column label="Peso bruto total" field="BRUTO_TOTAL" sortable>
            <span v-if="!checkIfValid(props.row.BRUTO_TOTAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.BRUTO_TOTAL)">{{
              props.row.BRUTO_TOTAL
            }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad de mercancia"
            field="TOTAL_MERCANCIA"
            sortable
          >
            <span v-if="!checkIfValid(props.row.TOTAL_MERCANCIA)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.TOTAL_MERCANCIA)">{{
              props.row.TOTAL_MERCANCIA
            }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showPorteCard(props.row)"
                    v-if="props.row.STATUS_FAC !== 'SF'"
                    >Ver carta porte</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showAcuseXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'CA'"
                    >Ver acuse de cancelación</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showPorteCardXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Ver XML</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="downloadPorteCardPDF(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Descargar PDF</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openSendCFDI(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Enviar CFDI</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    @click="cancelPorteCard(props.row._id)"
                    >Cancelar carta porte</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddPorteCardModal from "./AddPorteCardModal";
import PorteCardCancellationModal from "../../Accounting/components/PorteCardCancellationModal";
import PrintPorteCard from "./PrintPorteCard";
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import moment from "moment";
import SendCFDIModal from "../../Accounting/components/SendCFDIModal";
import { EventBus } from "../../../event-bus.js";

export default {
  name: "PorteCardsDetails",
  props: ["saleInformation", "invoiceInformation"],
  components: {},
  data() {
    return {
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      TOTAL_PAGADO: 0,
    };
  },
  mounted() {
    moment.locale("es");

    EventBus.$on("closeParetModal", () => {
      this.closeModal();
    });
  },
  methods: {
    showPorteCard(porteCardInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPorteCardModal,
        props: {
          saleInformation: this.saleInformation,
          objectInformation: porteCardInformation,
          invoiceInformation: this.invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async downloadPorteCardPDF(porteCardId) {
      try {
        let response = await this.$store.dispatch("GETPORTECARDPDF", {
          porteId: porteCardId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al obtener la información del PDF`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el pdf`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async cancelPorteCard(porteId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PorteCardCancellationModal,
        props: {
          saleId: this.saleInformation._id,
          porteId: porteId,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showPorteCardXML(porteCardId) {
      try {
        let response = await this.$store.dispatch("GETPORTECARDXML", {
          porteId: porteCardId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async showAcuseXML(porteId) {
      try {
        let response = await this.$store.dispatch("GETACUSEPORTECARDXML", {
          porteId: porteId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse de la carta porte`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse de la carta porte`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(porteId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          porteId: porteId,
          action: "SENDCFDIPORTECARD",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    currentObjects() {
      return this.invoiceInformation.CARTAS_PORTE.map((singlePorteCard) => {
        const singleProteCardWithProps = { ...singlePorteCard };
        singleProteCardWithProps.formatedDate = moment(
          singlePorteCard.FECHA_SALIDA
        ).format("DD-MM-YYYY HH:mm");
        return singleProteCardWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
