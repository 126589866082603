var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"global-lists-controls",attrs:{"grouped":"","group-multiline":""}}),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-container"}),_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1)]),_c('div',{staticClass:"global-list-main-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de envios de material: "),_c('b',[_vm._v(_vm._s(_vm.materialDeliveries.length))])]),_c('b-table',{ref:"sales",attrs:{"pagination-position":"both","data":_vm.materialDeliveries,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedDeliveried,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"desc","checkbox-position":"left","default-sort":"CLAVE_VENTA"},on:{"update:checkedRows":function($event){_vm.checkedDeliveried=$event},"update:checked-rows":function($event){_vm.checkedDeliveried=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: "Información de envio de material",
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay recepciones de material")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e(),(!column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"controls"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openMaterialReceptionDetails(props.row, true)}}},[_vm._v("Ver detalles de envio de material")]),(
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.global.sendMaterialDelivery ===
                      'S' &&
                    _vm.permissions &&
                    _vm.permissions.global.sendMaterialDelivery === 'S'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showPrintOrSendMaterialDelivery(props.row)}}},[_vm._v("Descargar o enviar envio de material")]):_vm._e(),(
                    (_vm.saleInformation.STATUS_FAC === 'SF' ||
                      _vm.saleInformation.STATUS_FAC === 'PF' ||
                      _vm.saleInformation.STATUS_FAC === 'PP') &&
                    _vm.saleInformation.requireParcialInvoiceAfterSend &&
                    !_vm.saleInformation.sendMaterial &&
                    !props.row.FACTURA
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){_vm.startConfirmationEvent(
                      'success',
                      function () {
                        _vm.saveInvoice(props.row);
                      },
                      'Facturar envio de material'
                    )}}},[_vm._v("Facturar envio de material")]):_vm._e()],1)])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }